.deviceBox .contentInTop {
  min-width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.deviceBox .contentInColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.deviceBox .contentInLeft {
  width: 35%;
}

.deviceBox .contentInRight {
  width: 50%;
}

.deviceBox .contentRightTop {
  font-size: 2vmin;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.deviceBox .text {
  text-align: center;
}

.deviceBox .contentRight {
  margin-top: 15px;
  height: 50vh;
  overflow-y: auto;
  background-color: #fff;
}

.deviceBox .bar {
  width: 100%;
  height: 3vmin;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  font-size: 2vmin;
  margin: 1.5vmin 0;
  padding-top: 5px;
  margin-bottom: 3vmin;
}

.deviceBox .contentButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto;
}

.deviceBox .searchBar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .deviceBox .contentInLeft {
    width: 50%;
  }

  .deviceBox .contentInRight {
    width: 45%;
  }

  .deviceBox .contentRightTop {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .deviceBox .contentInTop {
    width: 95vmin;
  }

  .deviceBox .contentInLeft {
    margin-top: 10px;
    width: 48%;
  }

  .deviceBox .contentInRight {
    width: 46%;
  }

  .deviceBox .contentRightTop {
    flex-direction: row;
  }
}

@media screen and (max-width: 550px) {
  .deviceBox .contentIn {
    margin-top: 30px;
    min-height: 75vh;
  }

  .deviceBox .contentInTop {
    margin-top: 10px;
    flex-direction: column;
  }

  .deviceBox .bar {
    width: 83vmin;
    font-size: 4vmin !important;
    margin: 6vmin 0;
  }

  .deviceBox .ant-input-affix-wrapper,
  .deviceBox .ant-input,
  .ant-picker {
    width: 50vmin !important;
    height: 8vmin;
    font-size: 4vmin !important;
  }

  .deviceBox
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    width: 50vmin !important;
  }

  .deviceBox .content {
    padding-bottom: 20vmin;
  }

  .deviceBox .contentButton {
    margin-bottom: 10px;
  }

  .deviceBox .contentInLeft {
    margin: 0 auto;
    margin-top: 10px;
    width: 90%;
  }

  .deviceBox .contentInRight {
    margin-top: 10px;
    width: 90%;
    margin: 0 auto;
    overflow-x: auto;
  }

  .deviceBox .contentRightTop { 
    font-size: 5vmin;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin-top: 4vmin;
  }

  .deviceBox .searchBar { 
    margin-top: 3vmin;
  }

  .deviceBox .searchButton {
    width: 22vmin;
    height: 8vmin;
    font-size: 4vmin;
    margin-left: 5vmin;
  } 

  .deviceBox
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
      height: 8vmin !important;
  }

  .deviceBox .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    font-size: 4vmin !important;
  }
}
