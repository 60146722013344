.firstBox .content {
  width: 80vmin;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 18vmin auto;
}

.firstBox .bar {
  width: 50vmin;
  height: 4vmin;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2vmin;
  margin: 1.5vmin 0;
}


@media screen and (max-width: 1024px) {
  .firstBox .content {
    padding-bottom: 20%;
  }
}

@media screen and (max-width: 768px) {
  .firstBox .content {
    padding-bottom: 60%;
  }
}

@media screen and (max-width: 550px) {
  .firstBox .content {
    padding-bottom: 100%;
  }

  .firstBox .bar {
    width: 100vmin;
    font-size: 4vmin !important;
    margin: 5vmin 0;
  }

  .firstBox .ant-input-affix-wrapper,
  .firstBox .ant-input,
  .ant-picker {
    width: 50vmin !important;
    height: 8vmin;
    font-size: 4vmin !important;
  }
}
