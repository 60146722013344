@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 550px) {
  .backButtonStyle {
    width: 30vmin !important;
    height: 9vmin !important;
    font-size: 5vmin !important;
    border-radius: 2vmin !important;
  }
}
