.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5vmin;
  background-color: #3e3e3e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer {
  font-size: 2vmin;
  color: #fff;
  line-height: 3vmin;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .footer {
    height: 8vmin;
  }

  .timer { 
    font-size: 2.5vmin;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    height: 10vmin;
  }

  .timer { 
    font-size: 4vmin;
  }
}
