@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 550px) {
  .buttonStyle {
    width: 45vmin;
    height: 9vmin;
    font-size: 5vmin;
    border-radius: 2vmin !important;
  }
}
