.firstBox .content {
  width: 80vmin;
  /* height: 40vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 18vmin auto;
  padding-bottom: 10%;
}

.firstBox .bar {
  width: 40vmin;
  height: 4vmin;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2vmin;
  margin: 1.5vmin 0;
}

.forget {
  color: #ffffff;
  font-size: 1.7vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

input.checkbox {
  width: 1.5vmin;
  height: 1.5vmin;
  margin-left: 1.5vmin;
  margin-right: 0.5vmin;
}

.register {
  color: #ffdb1e;
  font-size: 2vmin;
  margin-left: 1.5vmin;
  padding-left: 1.5vmin;
  border-left: 1px solid #fff;
  cursor: pointer;
}

.forgetIcon {
  width: 2vmin;
  height: 2vmin;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1vmin;
}

@media screen and (max-width: 1024px) {
  .firstBox .content {
    padding-bottom: 50%;
  }
}

@media screen and (max-width: 768px) {
  .firstBox .content {
    padding-bottom: 50%;
  }
}

@media screen and (max-width: 550px) {
  .firstBox .content {
    padding-bottom: 80%;
    margin: 25vmin auto !important;
  }

  .firstBox .contentTitle {
    font-size: 5vmin;
    margin-bottom: 2vmin;
  }

  .firstBox .bar {
    width: 100vmin;
    font-size: 4vmin !important;
    margin: 5vmin 0;
  }

  .firstBox .ant-input-affix-wrapper,
  .firstBox .ant-input,
  .ant-picker {
    width: 50vmin !important;
    height: 8vmin;
    font-size: 4vmin !important;
  }

  .forgetIcon {
    width: 4vmin;
    height: 4vmin;
  }

  .forget {
    margin-top: 8vmin;
    font-size: 4vmin;
  }

  .register {
    margin-top: 8vmin;
    font-size: 5vmin;
  }  

  input.checkbox {
    width: 4vmin;
    height: 4vmin;
  }
}
