.addDeviceBox .contentInTop {
  min-width: 68%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addDeviceBox .contentInColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.addDeviceBox
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 27vmin !important;
  border-radius: 0.5vmin !important;
  border: 0px;
  margin-left: 2vmin !important;
  color: #000;
  text-align: left !important;
  font-size: 1.5vmin !important;
  height: 4vmin !important;
  display: flex;
  align-items: center;
}

.addDeviceBox .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  font-size: 1.5vmin !important;
  display: flex;
  align-items: center;
}


.addDeviceBox .bar {
  width: 100%;
  height: 4vmin;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  font-size: 2vmin;
  margin: 1.5vmin 0;
  padding-top: 20px;
  margin-bottom: 3vmin;
}

.addDeviceBox .contentButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto;
}

@media screen and (max-width: 768px) {
  .addDeviceBox .contentInTop {
    width: 85vmin;
  }
}

@media screen and (max-width: 550px) {
  .addDeviceBox .contentIn {
    margin-top: 30px;
  }

  .addDeviceBox .contentInTop {
    margin-top: 10px;
    flex-direction: column;
  }

  .addDeviceBox .bar {
    width: 85vmin;
    font-size: 4vmin !important;
    margin: 6vmin 0;
  }

  .addDeviceBox .ant-input-affix-wrapper,
  .addDeviceBox .ant-input,
  .ant-picker {
    width: 50vmin !important;
    height: 8vmin;
  }

  .addDeviceBox
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    width: 50vmin !important;
  }

  .addDeviceBox
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
      height: 8vmin !important;
  }

  .addDeviceBox .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    font-size: 2vmin !important;
  }

  .addDeviceBox .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    font-size: 4vmin !important;
  }
}
