@media screen and (max-width: 1024px) {
  .indexBox .contentIn {
    overflow-x: auto;
  }

  .indexBox .ant-table-thead > tr > th {
    width: 150px;
  }
}

@media screen and (max-width: 550px) {
  .indexBox .ant-table-thead > tr > th {
    width: 170px;
  }
}
