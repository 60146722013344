* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: Microsoft JhengHei;
}

body {
  background-color: #222222;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

i {
  color: red;
  padding-right: 2px;
}

/* antd row之間間距 0  */
.ant-col {
  min-height: 0px !important;
}

/* 輸入框樣式 */
.ant-form-item {
  margin-bottom: 0px !important;
}

/* 確認按鈕 */
.ant-btn-primary {
  border-color: #ffd500 !important;
  background-color: #ffdb1e !important;
  color: #000 !important;
}

.ant-input {
  overflow: hidden;
}

.ant-btn:hover {
  color: #000 !important;
  border-color: #ffdb1e !important;
}

.wrapper {
  width: 100%;
  min-width: 1024px;
  background-color: #222222;
  padding-top: 8vmin;
} 

.loginWrapper {
  width: 100%;
  min-width: 1024px;
  height: 100vh;
  background-color: #222222;
  padding-top: 8vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2vmin;
  padding-bottom: 6vmin;
}

/* 按鈕 */
.buttonStyle {
  width: 18vmin;
  height: 5vmin;
  font-size: 2vmin;
}

/* 登入＆忘記密碼＆註冊輸入框 */
.inputBox .ant-input-affix-wrapper,
.inputBox .ant-input,
.ant-picker {
  width: 27vmin !important;
  height: 4vmin;
  border-radius: 0.5vmin !important;
  border: 0px;
  margin-left: 2vmin !important;
  padding: 1vmin 1.5vmin !important;
  color: #000;
  font-size: 1.5vmin !important;
}

.inputBox .ant-input-affix-wrapper > .ant-input {
  width: 100% !important;
  height: initial !important;
  padding: initial !important;
  margin: initial !important;
}

/* 登入＆忘記密碼＆註冊標頭 */
.contentTitle {
  color: #fff;
  font-size: 2.5vmin;
  margin-bottom: 1vmin;
}

/* 是否已加入賴群 */
.ant-radio-group {
  width: 29vmin !important;
  text-align: left;
  padding-left: 20px !important;
}

.ant-radio-group span{
  color: white !important;
}

/* 公布欄 */
.modalBox .ant-form-item {
  width: 75% !important;
}

/* 類別icon */
.typeSelectIcon {
  width: 3vmin;
  height: 3vmin;
  border-radius: 100%;
  background-color: #ffdb1e;
  color: #000;
  font-weight: bold;
  margin-left: 1vmin;
}

.typeSelectIcon:hover {
  cursor: pointer;
}

/* 因.bar是靠右對齊 此樣式為沒有選項類別Icon來塞位置排版 */
.typeSelectIconBox {
  width: 3vmin;
  height: 3vmin;
  margin-left: 1vmin;
}

/* 分類樣式 */
li.typeBoxLi {
  min-height: 5vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

li.typeBoxLi a {
  color: black;
  font-size: 1.7vmin;
}

.ant-radio-wrapper .ant-radio span,
.ant-radio-wrapper {
  font-size: 2vmin !important;
}

.ant-empty-normal {
  margin: 15vmin 0 !important;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    min-width: 550px;
  } 
  .deviceBox .contentInLeft {
    width: 42%;
  }

}


@media screen and (max-width: 550px) {
  .wrapper {
    padding-top: 15vmin;
    min-width: initial;
    height: 100%;
  } 

  .profileBox .contentIn { 
    padding: 10vmin;
  }

  /* 類別icon */
  .typeSelectIcon {
    width: 6vmin;
    height: 6vmin;
    margin-left: 1.5vmin;
  }

  .typeSelectIconBox {
    width: 6vmin;
    height: 6vmin;
    margin-left: 1.5vmin;
  }

  /* 是否已加入賴群 */
  .ant-radio-group {
    width: 50vmin !important;
  }

  /* 分類樣式 */
  li.typeBoxLi {
    min-height: 10vmin;
  }

  li.typeBoxLi a {
    font-size: 4vmin;
  }

  .ant-radio-wrapper {
    font-size: 4vmin !important;
  }

}