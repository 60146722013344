@media screen and (max-width: 1024px) {
  .operateButton {
    min-width: 10vmin !important;
    height: 5vmin !important;
    font-size: 2.5vmin !important;
    border-radius: 1vmin !important;
  }
}

@media screen and (max-width: 550px) {
  .operateButton {
    width: 18vmin !important;
    height: 8vmin !important;
    font-size: 4vmin !important;
    border-radius: 2vmin !important;
  }

  .operateButtonBig {
    width: 32vmin !important;
    height: 9vmin !important;
    font-size: 4vmin !important;
    border-radius: 2vmin !important;
  }
}
