.passWordPage .bar {
  width: 100%;
  height: 4vmin;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2vmin;
  margin: 1.5vmin 0;
  padding-top: 20px;
  margin-bottom: 3vmin;
}

.passWordPage .contentPage {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 10px auto;
}

.passWordPage .contentInputBox {
  width: 100%;
  margin-top: -15vmin;
}

.passWordPage .contentButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  /* .passWordPage .contentIn {
    padding: 5vmin 28vmin;
  } */
}

@media screen and (max-width: 550px) {
  .passWordPage .contentIn {
    padding: 10vmin;
  }

  .passWordPage .bar {
    width: 75vmin;
    font-size: 4vmin !important;
    margin: 8vmin 0;
  }
}
