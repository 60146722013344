.profileBox .contentPage {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 10px auto;
}

.profileBox .contentInputBox {
  width: 100%;
  margin-top: -15vmin;
}

.profileBox a {
  font-size: 2vmin !important;
  color: #fff;
}

.profileBox a:hover {
  color: #fff;
}

.profileBox a.active {
  font-size: 2.5vmin !important;
  font-weight: bold;
  color: #ffdb1e !important;
}

.profileBox .contentTop {
  height: 5vmin;
}

.profileBox .contentIn {
  /* padding: 5vmin 38%; */
  min-height: 75vh;
}

.profileBox .profileBoxIn {
  /* width: 30%; */
  margin: 12px auto;
  padding: 2vmin;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 68vh;
}

.profileBox .bar {
  width: 100%;
  height: 4vmin;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2vmin;
  margin: 1.5vmin 0;
  padding-top: 20px;
  margin-bottom: 3vmin;
}

@media screen and (max-width: 1024px) {
  /* .profileBox .contentIn {
    padding: 5vmin 45vmin;
  } */

  /* .profileBox .profileBoxIn {
    width: 40%;
  } */
}

@media screen and (max-width: 768px) {
  /* .profileBox .contentIn {
    padding: 5vmin 30vmin;
  } */

  /* .profileBox .profileBoxIn {
    width: 50%;
  } */
}

@media screen and (max-width: 550px) {
  .profileBox .contentTop {
    height: 8vmin;
  }

  /* .profileBox .contentIn {
    padding: 10vmin;
  } */

  .profileBox .profileBoxIn {
    width: 100%;
  }

  .profileBox .bar {
    width: 75vmin;
    font-size: 4vmin !important;
    margin: 6vmin 0;
  }

  .profileBox .ant-input-affix-wrapper,
  .profileBox .ant-input,
  .ant-picker {
    width: 50vmin !important;
    height: 8vmin;
    font-size: 3.5vmin !important;
  }

  .profileBox a {
    font-size: 4vmin !important;
  }
  
  .profileBox a.active {
    font-size: 4.5vmin !important;
  }
}
