@media screen and (max-width: 550px) {
    .deviceBox .ant-table-wrapper {
        overflow-x: auto;
    }

    .deviceBox .ant-table {
        overflow-x: auto;
    }

    .deviceBox .ant-table table > tr > th {
        width: 170px;
    }
}
