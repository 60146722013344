.registerBox .content {
  width: 80vmin;
  /* height: 40vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10vmin auto;
  padding-bottom: 10%;
}

.registerBox .bar {
  width: 52vmin;
  height: 4vmin;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2vmin;
  margin: 1.5vmin 0;
}

@media screen and (max-width: 1024px) {
  .registerBox .content {
    padding-bottom: 10%;
  }
}

@media screen and (max-width: 768px) {
  .registerBox .content {
    padding-bottom: 50%;
  }
}

@media screen and (max-width: 550px) {
  .registerBox .content {
    padding-bottom: 80%;
  }

  .registerBox .contentTitle {
    font-size: 5vmin;
    margin-bottom: 2vmin;
  }

  .registerBox .bar {
    width: 100vmin;
    font-size: 4vmin !important;
    margin: 5vmin 0;
  }

  .registerBox .ant-input-affix-wrapper,
  .registerBox .ant-input,
  .ant-picker {
    width: 50vmin !important;
    height: 8vmin;
    font-size: 4vmin !important;
  }
}