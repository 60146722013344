.indexBox .contentTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  height: 5vmin;
}
.memberPage .contentTop {
  justify-content: space-between;
}

.indexBox .contentTopLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.indexBox .contentTopLeft span {
  color: #fff;
}

.indexBox .contentLabel {
  margin-right: 10px;
}

.indexBox .ant-select {
  width: 30vmin;
}

.memberPage .ant-table {
  font-size: 2vmin !important;
  font-weight: bold !important;
  color: #fff !important;
  background-color: #3e3e3e !important;
  min-height: 60vh !important;
}

.memberPage .ant-table-tbody > tr {
  text-align: center !important;
}

.memberPage .ant-table-thead > tr > th {
  font-size: 2vmin !important;
  font-weight: bold !important;
  color: #ffdb1e !important;
  text-align: center !important;
  background-color: #3e3e3e !important;
}

.ant-table-tbody > tr > td {
  padding: 20px;
}

.ant-table-thead > tr > th,
.ant-picker-input > input,
.ant-table-pagination.ant-pagination {
  font-size: 1.5vmin !important;
}

.memberPage .ant-table tr:hover > td,
td.ant-table-column-sort {
  cursor: pointer;
  color: #fff !important;
  background-color: #222222 !important;
}

.ant-tooltip-inner {
  font-size: 1.8vmin !important;
}

.hr {
  position: fixed;
  left: 0;
  top: 8vmin;
  width: 100%;
  height: 1vmin;
  z-index: 1;
  background-color: #3e3e3e;
}

.contentTop {
  width: 100%;
  height: 8vmin;
  display: flex;
  align-items: center;
}

.contentIn {
  width: 100%;
  overflow-x: auto;
  min-height: 70vh;
  background-color: #3e3e3e;
  border-radius: 1vmin;
  margin: 12px auto;
  padding: 1vmin 0vmin;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.indexBox .contentTopRight {
  display: flex;
  align-items: center;
}

.indexBox #computerUI {
  display: flex;
} 

.indexBox #phoneUI {
  display: none;
} 

@media screen and (max-width: 1024px) {
  .content {
    padding-bottom: 20vmin;
  }
}

@media screen and (max-width: 550px) {
  .hr {
    top: 13vmin;
    height: 3vmin;
  }
  
  .indexBox .contentTop {
    height: 10vmin;
  }

  .content {
    padding: 0 4vmin;
    padding-top: 5vmin;
    padding-bottom: 25vmin;
  }

  .memberPage .ant-select {
    width: 50vmin !important;
  }

  .memberPage .ant-table-thead > tr > th ,
  .memberPage .ant-table,
  .ant-picker-input > input {
    font-size: 4vmin !important;
  }

  .memberPage .ant-table-column-sorters {
    font-size: 4vmin !important;
  }
}
