.loginHeader {
  width: 100%;
  height: 8vmin;
  background-color: #3e3e3e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vmin;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 550px) {
  .loginHeader {
    height: 12vmin;
  }
}
