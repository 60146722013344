.header {
  width: 100%;
  height: 8vmin;
  background-color: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vmin;
  position: fixed;
  top: 0;
  z-index: 100;
}

.sysName {
  font-size: 3vmin;
  font-weight: bold;
  color: #ffdb1e;
  line-height: 30px;
  letter-spacing: 1px;
}

.sysName:hover {
  cursor: pointer;
}

.setting {
  min-width: 10vmin;
  height: 2.5vmin;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 2vmin;
}

.settingIcon {
  cursor: pointer;
}

/* .ant-dropdown-menu {
  width: 20vmin !important;
}

.ant-dropdown-menu-item {
  font-size: 1.5vmin !important;
} */

@media screen and (max-width: 1024px) { 
  
}

@media screen and (max-width: 550px) {
  /* 表頭 */
  .header {
    height: 15vmin;
  }

  .sysName {
    font-size: 5vmin;
  }

  .setting {
    width: 24vmin;
    height: 5vmin;
    font-size: 5vmin;
  }

  .settingIcon {
    font-size: 5vmin !important;
  }
}
