.topBar .lineContent {
  display: flex;
  flex-direction: column;
}

.topBar .lineImg {
  width: 7vmin;
  height: 7vmin;
  margin-right: 1vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topBar .lineImg img {
  width: 100%;
}

.topBar .lineImg img:hover {
  cursor: pointer;
}

.topBar .lineContent a {
  color: #fff;
  font-size: 1.8vmin;
}

.topBar .lineContent a:hover {
  cursor: inherit;
}

.topBar .lineContent a.lineTitle {
  color: #ffdb1e;
  font-size: 2vmin;
  font-weight: bold;
}

.headerBar {
  width: 100%;
  min-height: 8vmin;
  background-color: #3e3e3e;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.headerBarLeft {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.headerBarRight {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.topBar .searchBar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 2vmin;
}

.topBar input.search {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 2vmin;
}

.topBar .lineBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
}

.lineContentIn {
  height: 3.5vmin;
  overflow-x: auto;
}

.topBar #computerUI {
  display: flex !important;
} 

.topBar #phoneUI {
  display: none !important;
} 

@media screen and (max-width: 1024px) {
  .headerBarRight {
    width: 35%;
  }

  .headerBarLeft {
    width: 65%;
  }
}

@media screen and (max-width: 1000px) {
  .topBar .lineContent {
    flex-direction: row;
  }

  .topBar .lineImg {
    width: 10vmin;
    height: 10vmin;
  }

  .lineContentIn {
    height: 6vmin;
    overflow-x: auto;
  }

  .topBar .lineContent a.lineTitle {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1000px) { 
  .headerBar {
    min-height: 12vmin;
  }

  .topBar .lineContent {
    flex-direction: column;
    height: 10vmin;
    overflow-x: auto;
  }
}

@media screen and (max-width: 550px) {
  .topBar #computerUI {
    display: none !important;
  } 
  
  .topBar #phoneUI {
    display: flex !important;
  } 

  .headerBar {
    min-height: 28vmin;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start; 
  }

  .headerBarRight,
  .headerBarLeft {
    width: 100%;
  }

  .headerBarLeft {
    flex-direction: column;
    justify-content: space-between;
  }

  .headerBarRight {
    margin-top: 5px;
    justify-content: space-around;
  }

  .topBar .searchBar {
    width: 95vmin;
  }

  .topBar input.search {
    width: 68vmin;
    height: 10vmin;
    font-size: 4vmin;
  }

  .topBar .searchButton {
    width: 20vmin;
    height: 10vmin;
    font-size: 4vmin;
  }

  .indexBox .contentTop {
    width: 100%;
    overflow-x: auto;
  }

  .topBar .lineBox {
    width: 90vmin;
    height: 20vmin;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 15px;
  }

  .topBar .lineContent {
    align-items: center;
    flex-direction: row;
    height: 15vmin;
    overflow-x: hidden !important;
  }

  .lineContentIn {
    height: 12vmin !important;
  }

  .topBar .lineContent a {
    font-size: 3.8vmin;
  }

  .topBar .lineContent a.lineTitle {
    font-size: 4vmin;
  }

  .topBar .lineImg {
    width: 7vmin;
    height: 7vmin;
    margin-right: 1vmin;
  }

  .lineContentIn {
    height: 12vmin;
    overflow-x: hidden !important;
  }

  .lineContentTop {
    display: flex;
    flex-direction: row;
    overflow-x: initial !important;
  }

  .topBar .lineContentTop a:hover {
    cursor: inherit;
  }
  
  .topBar .lineContentTop a.lineTitle {
    color: #ffdb1e;
    font-size: 4vmin;
    font-weight: bold;
  }
  
}
